define(['app'], function(app) {
  const storyStreamPdpWrapper = function () {
    const component = {};

    const _init = (element) => {
      component.element = element;

      (function () {
        let lk = document.createElement('script');
        lk.type = 'text/javascript';
        lk.src ='https://ucarecdn.com/libs/widget/3.17.2/uploadcare.full.min.js'
        lk.crossOrigin='anonymous';
        lk.integrity= 'sha256-RIw1QGksH8N9pp5Ie4XJxpmK2I+HVuM9a8i8lvo1uyY='; //change this - ask them about this
        document.getElementsByTagName('head')[0].appendChild(lk);
      })();
    }

    component.init = _init;
    
    return component
  }

  return storyStreamPdpWrapper;
});
